export default class {

    mount() {
        const btn = document.getElementById('random-btn')

        if (btn) {
            btn.addEventListener('click', () => window.location.href = '/random')
        }
    }

}
