import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'intersection-observer'
import SiteHeader from './modules/site-header'
import {
    RandomButton,
} from './components'

const components = [
    new SiteHeader(),
    new RandomButton()
]

components.forEach(component => component.mount())
