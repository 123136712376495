import { $, $$ } from './../helpers/qsa'

export default class {

    constructor() {
        this.root = $('.site-header')
    }

    mount() {
        // Menu
        this.menuOpenEvent = new CustomEvent('menutoggle', { detail: { open: true }, bubbles: true })
        this.menuCloseEvent = new CustomEvent('menutoggle', { detail: { open: false }, bubbles: true })
        this.menuShownEvent = new CustomEvent('menushown', { detail: { shown: true }, bubbles: true })
        this.menuHiddenEvent = new CustomEvent('menushown', { detail: { shown: false }, bubbles: true })

        $$('.js-menu-toggle').forEach(el => el.addEventListener('click', this.handleMenuToggle.bind(this)))
    }

    handleMenuToggle(e) {
        const button = e.currentTarget
        const expanded = button.getAttribute('aria-expanded') === 'true'

        // Update button
        button.innerHTML = expanded ? 'Menu' : 'Close'

        button.setAttribute('aria-expanded', !expanded)
        button.blur()

        this.root.classList.toggle('site-header--menu-active', !expanded)
        document.documentElement.style.overflow = expanded ? 'auto' : 'hidden'

        // Dispatch event
        this.root.dispatchEvent(expanded ? this.menuCloseEvent : this.menuOpenEvent)
    }

}
